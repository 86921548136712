<template>
	<div class="nrfl">
		<el-form :inline="true">
			<!-- 	<el-form-item>
				<el-input v-model="searchValue" style="width: 400px" placeholder="请输入分类名称查询">
					<el-button slot="append" @click="getDataList" icon="el-icon-search"></el-button>
				</el-input>
				<el-input v-model="searchValue2" style="width: 400px" placeholder="请输入字典名称查询">
					<el-button slot="append" @click="getDataList" icon="el-icon-search"></el-button>
				</el-input>
				
			</el-form-item>-->
			<div>
				<el-form-item>
					<el-button type="primary" @click="showorderDialog()">添加</el-button>
				</el-form-item>
				<!-- 	<el-form-item>
					<el-button type="danger" @click="deleteHandle()" :disabled="dataListSelections.length <= 0">删除</el-button>
				</el-form-item>-->
			</div>
		</el-form>
		<el-table
			:data="dataList"
			:header-cell-style="{background:'#FAFAFA'}"
			v-loading="dataListLoading"
			@selection-change="selectionChangeHandle"
			style="width: 100%"
		>
			<!-- <el-table-column type="selection"  width="50">
			</el-table-column>-->
			<!-- 	<el-table-column  fixed="left" type="selection" 
			width="50">-->
			<!-- </el-table-column> -->
			<el-table-column prop="name" width="140" label="病种名称"></el-table-column>
			<!-- 	<el-table-column prop="classify"  label="所属模块">
			</el-table-column>-->

			<el-table-column prop="type" label="所属上级"></el-table-column>
			<el-table-column prop="weight" width="100" label="排序权重"></el-table-column>
			<el-table-column prop="createTime" width="200" label="添加时间"></el-table-column>
			<el-table-column prop="createName" width="200" label="添加人"></el-table-column>
			<el-table-column fixed="right" header-align="right" align="right" width="100" label="操作">
				<template slot-scope="scope">
					<el-button type="text" size="small" @click="showEditDialog(scope.row)">编辑</el-button>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination
			@size-change="sizeChangeHandle"
			@current-change="currentChangeHandle"
			:current-page="pageIndex"
			:page-sizes="[10, 20, 50, 100]"
			:page-size="pageSize"
			:total="totalPage"
			layout="total, sizes, prev, pager, next, jumper"
		></el-pagination>
		<el-dialog title="病种管理" :visible.sync="dialogVisible" width="800px" :append-to-body="true">
			<el-form label-width="140px" @keyup.enter.native="dataFormSubmit()" class="form-con-center4">
				<el-form-item label="病种内容" required>
					<el-input style="width: 350px;" v-model="name" type="num"></el-input>
				</el-form-item>
				<!--	<el-form-item label="所属上级" required>
					 <el-select @change="getyjcd" style="width: 300px;"  v-model="type" placeholder="请选择">
	
						<el-option label="基础病种" value="基础病种"></el-option>
				
					</el-select>
				</el-form-item>-->
				<el-form-item v-if="type!=''" label="所属上级">
					<el-select style="width: 350px;" v-model="yjcd" placeholder="请选择">
						<el-option v-for="item in yjcdList" :key="item.id" :label="item.name" :value="item.name"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="排序权重" required>
					<el-input :min="0" style="width: 350px;" v-model="weight" type="number"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="dataFormSubmit()">确定</el-button>
				<el-button @click="dialogVisible = false">取消</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import Qs from "qs";
export default {
  name: "informationList",
  data() {
    return {
      searchValue: "", //搜索内容
      typeArr: [], //资讯分类
      select: "1",
      select2: "",
      dataList: [],
      yjcdList: [],
      yjcd: "",
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      name: "",
      type: "基础病种",
      weight: 0,
      id: "",
      dataListLoading: false,
      dataListSelections: [],
      dialogVisible: false,
      searchValue2: "",
    };
  },
  mounted() {
    this.getDataList();
    this.getType();
    this.getyjcd();
  },
  methods: {
    dataFormSubmit(val) {
      if (this.name == "") {
        this.$message.warning("请输入分类名称");
        return;
      }
      if (this.type == "") {
        this.$message.warning("请选择所属模块");
        return;
      }

      if (this.weight === "") {
        console.log(this.weight);
        this.$message.warning("请输入排序权重");
        return;
      }
      let newType = "";
      if (this.yjcd != "") {
        newType = this.yjcd;
      } else {
        newType = this.type;
      }
      var that = this;
      let obj = {
        id: this.id,
        type: newType,
        weight: this.weight,
        name: this.name,
        createName: JSON.parse(localStorage.userInfo).name,
        classify: "基础病种",
        // ename:'全周期问卷分类'
      };
      that
        .$http({
          url:
            that.id == ""
              ? that.$http.adornUrl("/dict/insert")
              : that.$http.adornUrl("/dict/update"),
          method: "post",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          data: Qs.stringify(obj),
        })
        .then(({ data }) => {
          if (data.status) {
            that.$message({
              message: data.msg,
              type: "success",
              duration: 1500,
              onClose: () => {
                that.getDataList();

                this.dialogVisible = false;
                that.yjcd = "";
              },
            });
          } else {
            that.$message.error(data.msg);
            that.flag = true;
            that.yjcd = "";
          }
        });
    },
    showorderDialog(row) {
      this.id = "";
      this.name = "";
      // this.type = ''
      this.weight = 0;

      this.dialogVisible = true;
    },
    showEditDialog(row) {
      this.name = row.name;
      this.type = row.classify;
      this.yjcd = row.type;
      this.weight = row.weight;
      this.id = row.id;
      this.dialogVisible = true;
    },
    addInformation() {
      this.$router.push({
        name: "addInformation",
      });
    },
    getDataList() {
      this.dataListLoading = true;
      this.$http({
        url: this.$http.adornUrl(
          "/dict/getDictList?pageNo=" +
            this.pageIndex +
            "&pageSize=" +
            this.pageSize +
            "&name=" +
            this.searchValue +
            "&type=基础病种" +
            "&classify=基础病种"
        ),
        method: "get",
      }).then(({ data }) => {
        this.dataList = data.data;
        this.totalPage = data.totalCount;
        this.dataListLoading = false;
      });
    },
    getyjcd() {
      this.$http({
        url: this.$http.adornUrl("/dict/list?type=" + this.type),
        method: "get",
      }).then(({ data }) => {
        this.yjcdList = data.data;
      });
    },
    getType() {
      this.dataListLoading = true;
      this.$http({
        url: this.$http.adornUrl("/dict/list?type=资讯类型"),
        method: "get",
      }).then(({ data }) => {
        this.typeArr = data.data;
      });
    },
    gotoUpdate(row) {
      localStorage.information = JSON.stringify(row);
      this.$router.push({
        name: "editInformation",
      });
    },
    gotoDetail(row, indexName) {
      localStorage.information = JSON.stringify(row);
      this.$router.push({
        name: "infodetail",
      });
    },
    handelChange(val) {
      this.getDataList();
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val;
      this.getDataList();
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val;
    },
    // 删除
    deleteHandle() {
      var that = this;
      let ids = "";
      if (this.dataListSelections.length == 0) {
        this.$message({
          message: "请选择要删除的项目！",
          type: "error",
        });
        return;
      } else {
        for (let i = 0; i < this.dataListSelections.length; i++) {
          if (i < this.dataListSelections.length - 1) {
            ids = ids + this.dataListSelections[i].id + ",";
          } else {
            ids = ids + this.dataListSelections[i].id;
          }
        }
      }

      this.$confirm(`确定进行删除操作?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonClass: "btn-custom-cancel",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$http({
            url: this.$http.adornUrl("/dict/delete"),
            method: "post",
            data: Qs.stringify({
              ids: ids,
            }),
          }).then(({ data }) => {
            if (data.status) {
              that.$message({
                message: "删除成功",
                type: "success",
                duration: 1500,
                onClose: () => {},
              });
              that.getDataList();
            } else {
              that.$message.error(data.msg);
            }
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.nrfl {
}
</style>